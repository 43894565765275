<template>
	<v-navigation-drawer
			floating
			:stateless="$vuetify.breakpoint.width >= 960"
			v-model="drawer"
			app
			overlay-opacity="0.6"
			:style="$vuetify.breakpoint.width >= 600 ? '' : 'background-color: rgba(0,0,0,0.2) !important'"
			:mini-variant="drawer_mini"
			:width="230"
	>
		<v-list class="py-0 sidebar">
			<div class="sidebar darken-1" style="height: 40px">
				<v-list-item class="overflow-hidden px-0 mx-4">
					<img :style="{filter: $vuetify.theme.themes.style.filter }" :src="require('@/assets/logo_dark.svg')" height="20" contain position="left center" style="margin-top: -10px; margin-left: 1px"/>
				</v-list-item>
			</div>
			<v-divider class="sidebar lighten-1"/>
			<v-divider class="sidebar lighten-1"/>
			<div style="overflow-y: auto; overflow-x: hidden" :style="{height: ($vuetify.breakpoint.height - 42) + 'px'}" class="sidebar scrollbar">
				<NodeTree
						v-for="(item,i) in items.children"
						v-if="item.position === 'sidebar'"
						:key="i"
						:isList="item.type === 'menu'"
						:isChild="false"
						:name="item.name"
						:icon="item.icon"
						:endpoint="item.endpoint"
						:subItems="item.children"
						:profondeur="1"
						:label="item.label"
						:item="item"
				/>
			</div>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: "SideBarLeft",
		components: {
			NodeTree: () => import("@/components/sidebar/NodeTree.vue")
		},
		computed: {
			items: {
				get() {
					return this.$store.getters.sidebar_routes
				},
				set(val) {
					this.$store.commit('CHANGE_SIDEBAR_ROUTES', val)
				}
			},
			drawer: {
				get() {
					return this.$store.getters["dashboard/drawer"];
				},
				set(val) {
					this.$store.commit("dashboard/CHANGE_DRAWER_VAL", val);
				}
			},
			drawer_mini: {
				get() {
					return this.$store.getters["dashboard/drawer_mini"];
				},
				set(val) {
					this.$store.commit("dashboard/CHANGE_DRAWER_MINI_VAL", val);
				}
			}
		}
	}
</script>

<style scoped>
	.scrollbar {
		scrollbar-color: var(--v-black-base) transparent !important;
	}
</style>
